import { Container, Nav, Navbar } from "react-bootstrap";
import WolfIcon from "./base/WolfIcon";
import { useEffect, useRef, useState } from "react";

function NavbarTop() {
  const [height, setHeight] = useState(0);
  const navbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (navbarRef?.current != null) {
      if (!isNaN(navbarRef.current.clientHeight)) {
        setHeight(navbarRef.current.clientHeight - 15);
      }
    }
  }, []);

  return (
    <Navbar ref={navbarRef} expand="xl" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">
          <WolfIcon height={height} width={height} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/projects">Projects</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default NavbarTop;
