import React from "react";
const buildConfig = {
  local: true,
  serverUrl: "https://jinming.ca/",
  // serverUrl: "http://10.0.0.91:3000/",
  wolfIconUrl: "/WolfIcons/wolf_icon_{}.png",
  wolfIconCount: 337,
};

const NetworkConfig = React.createContext(buildConfig);
const AppConfig = React.createContext(null);

export { NetworkConfig, AppConfig, buildConfig };
