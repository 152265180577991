import "../../../../node_modules/font-awesome/css/font-awesome.css";
import { Fragment, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import WorkProject from "../WorkProject";
import { Props as WProps } from "../WorkProject";

export interface Props {
  timeNode: string;
  timelineData: WProps;
}

const TimelineItem = (props: Props) => {
  const hideTimeSize = 990;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Fragment>
      <div className="timeline-item">
        <Row>
          {screenWidth > hideTimeSize ? (
            <Col xs={2} className="timeline-time d-flex align-items-center">
              <span className="timeline-time-content">{props.timeNode}</span>
            </Col>
          ) : null}
          <Col>
            <WorkProject {...props.timelineData} />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};
export default TimelineItem;
