import { Container } from "react-bootstrap";

const TimelineContainer = (props: any) => {
  return <Container className="timeline-container">{props.children}</Container>;
};
// const TimelineContainer = () => {
//   const timelineObjects: Array<TimelineObj> = [
//     {
//       title: "Student",
//       subtitle: "Northeaster University",
//       duration: "Sep 2022 - Now",
//       description: [
//         "Student in Master of Science in Computer Science",
//         "Current Course taken:",
//         "    Fundamental in AI",
//         "    Design Pattern",
//       ],
//       imageUrl: "/SkillIcons/neu.png",
//     },
//     {
//       title: "Full Time Unity Developer",
//       subtitle: "Koolhaus Games Inc",
//       duration: "Nov 2020 - Oct 2022",
//       description: [
//         "Effectively communicated with designers and implemented game UI and features as expected.",
//         "Contributed to major code refactoring by analyzing existing code structure, composing UML diagrams, comparing designs and implementing new design piece by piece.",
//         "Gained experience on game data backend management by working with Playfab and verifying different backend features.",
//         "Strong debugging skills gained by investigating and solved various tricky bugs in Game of Thrones: Conquest (GOTC) project while worked in a team doing contract work for Warner Bros",
//       ],
//       imageUrl: "/SkillIcons/koolhaus.png",
//     },
//     {
//       title: "Software Developer/Tester Intern",
//       subtitle: "Ontario Ministry of Health and Long-Term Care",
//       duration: "May 2018 - May 2019",
//       description: [
//         "Ensured the team project behaved correctly according to the business specifications with a high quality by performing automated re- gression tests and use case tests that covers various of aspects and environments",
//         "Enhanced the system's robustness by per- forming tests on various invalid inputs, such as special characters and Unicode.",
//         "Improved the projects testing environment by set up, configured and maintained the web server for our team's local testing sandbox, as well as adjusted the environment depending on the development and testing needs.",
//       ],
//       imageUrl: "/SkillIcons/mhltc.png",
//     },
//     {
//       title: "Student",
//       subtitle: "University of Toronto Scarborough",
//       duration: "Sep 2015 - April 2020",
//       description: ["Bachelor of Science - BS, Computer Science"],
//       imageUrl: "/SkillIcons/utsc.jpg",
//     },
//   ];
//   return (
//     <div className="timeline-container ">
//       <h2 className="section_heading">Experience</h2>
//       {timelineObjects.map((e, i) => {
//         return <TimelineItem {...e} key={`timeline-item-${i}`} />;
//       })}
//       {/* timelineObjects.map(e=>{
//       }) */}
//     </div>
//   );
// };
export default TimelineContainer;
