import { useContext, useEffect, useRef, useState } from "react";
import { NetworkConfig } from "../../DataCenter";
import { Carousel } from "react-bootstrap";
import { CarouselRef } from "react-bootstrap/esm/Carousel";

interface props {
  url: string;
  caption: string[];
  width?: number;
  height?: number;
}

const apiUrl = "portfolio/get_portraits";
const ImageSlider = (props: props) => {
  const host = useContext(NetworkConfig).serverUrl;
  // states
  const [portraitsInfo, setPortraitsInfo] = useState<props[]>([]);
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const [maxWidth, setMaxWidth] = useState<number>(0);

  useEffect(() => {
    async function getImages() {
      let resp;
      try {
        resp = await fetch(host + apiUrl);
      } catch (error) {
        console.log(error);
        return;
      }
      const portrait_imgs_resp = await resp.json();
      const portrait_infos: any[] = [];
      for (
        let index = 0;
        index < portrait_imgs_resp.portraitsInfo.length;
        index++
      ) {
        const e = portrait_imgs_resp.portraitsInfo[index];
        const imgUrl = host + e.url;
        const imgBlob = await (await fetch(imgUrl)).blob();
        const bmp = await createImageBitmap(imgBlob);
        const { width, height } = bmp;
        bmp.close();
        setMaxHeight((prev) => {
          return prev < height ? height : prev;
        });
        setMaxWidth((prev) => {
          return prev < height ? width : prev;
        });
        const dataUrl = URL.createObjectURL(imgBlob);
        portrait_infos.push({ url: dataUrl, caption: e.caption });
      }
      setPortraitsInfo(portrait_infos);
    }
    getImages();
  }, [host]);

  const carouselRef = useRef<CarouselRef>(null);
  const [imageContainerSize, setImageContainerSize] = useState([0, 0]);
  useEffect(() => {
    if (carouselRef.current) {
      const w = carouselRef.current!.element?.offsetWidth!;
      const h = (w * maxHeight) / maxWidth;
      if (!isNaN(h)) {
        setImageContainerSize([w, h]);
      }
    }
  }, [maxHeight, maxWidth]);

  return (
    <Carousel
      id="image_slider"
      ref={carouselRef}
      style={{ height: imageContainerSize[1], width: "100%" }}
    >
      {portraitsInfo.map((info, i) => {
        return (
          <Carousel.Item
            key={`carousel${i}`}
            style={{ height: imageContainerSize[1] }}
          >
            <img
              src={info.url}
              alt="it's a imageh"
              className=""
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
            <Carousel.Caption>{info.caption}</Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};
export default ImageSlider;
