import { useEffect } from "react";
import ImageSlider from "../ReactComponents/components/ImageSlider";
import { TechStack } from "../ReactComponents/components/TechStack";
import Bibliography from "../ReactComponents/components/Bibliography";
import { Container, Row, Col } from "react-bootstrap";
import ProjectTimeline from "../ReactComponents/components/ProjectTimeline";

export default function Home() {
  useEffect(() => {
    document.title = "Portfolio - Jinming Zhang";
  }, []);
  return (
    <div id="home">
      <Container style={{ paddingBottom: "4rem" }}>
        <Row>
          <Col>
            <Bibliography />
          </Col>
          <Col lg className="d-flex justify-content-center align-items-center">
            <ImageSlider url="" caption={[""]} />
          </Col>
        </Row>
      </Container>
      <ProjectTimeline />
      <TechStack />
      {/* <TimelineContainer /> */}
      {/* <MantineTest /> */}
    </div>
  );
}
