import { Container } from "react-bootstrap";
import TimelineContainer from "./Timeline/TimelineContainer";
import TimelineItem from "./Timeline/TimelineItem";
import TimelineGap from "./Timeline/TimelineGap";
import { Fragment, useEffect, useState } from "react";
import { Props as TimelineProps } from "./Timeline/TimelineItem";

const ProjectTimeline = () => {
  const [data, setData] = useState<TimelineProps[]>([]);
  useEffect(() => {
    fetch("/Data/WorkProjects/WorkProjectData.json")
      .then((r) => r.json())
      .then((dataList) => setData(dataList));
  }, []);
  return (
    <Container id="projectTimeline">
      <div className="section_heading d-flex justify-content-center">Work</div>
      <div className="section_heading d-flex justify-content-center">
        Projects
      </div>
      <hr></hr>

      <TimelineContainer>
        {data == null
          ? null
          : data.map((d, i) => {
              return (
                <Fragment key={`workproject-card-${i}`}>
                  <TimelineItem {...d}></TimelineItem>
                  <TimelineGap />
                </Fragment>
              );
            })}
      </TimelineContainer>
    </Container>
  );
};
export default ProjectTimeline;
