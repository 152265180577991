import { useContext, useEffect, useState } from "react";
import { NetworkConfig } from "../../DataCenter";
import { Container, Row } from "react-bootstrap";

interface bibioInfo {
  url: string;
  dataUrl: string;
  description: string;
}

export default function Bibliography() {
  const context = useContext(NetworkConfig);
  const hosturl = context.serverUrl;

  const [bibioInfo, setBibioInfo] = useState<bibioInfo>();
  useEffect(() => {
    async function getIcon() {
      let resp;
      try {
        resp = await fetch(hosturl + "portfolio/my_portfolio_icon");
      } catch (error) {
        console.log(error);
        return;
      }
      const info = await resp.json();
      const turl = hosturl + info.url;
      const imgBlob = await (await fetch(turl)).blob();
      // const durl = URL.createObjectURL(imgBlob);

      info.dataUrl = URL.createObjectURL(imgBlob);
      setBibioInfo(info);
    }
    getIcon();
  }, [hosturl]);
  return (
    <Container>
      <Row>
        <section id="bibliograph" className="align-content-center">
          <div className="my-pic justify-content-start">
            <img src={bibioInfo?.dataUrl} alt="cant find me..." />
          </div>
          <div id="bibli-title">Software Developer</div>
          <div className="my-bibli">
            <p>
              I am a full stack developer with over three years of professional
              experience in C#.
            </p>
            <p>I have help shipped 3 mobile games</p>
            <p>Actively learning multiple aspacts of technologies</p>
          </div>
        </section>
      </Row>
    </Container>
  );
}
