import React from "react";
import ReactDOM from "react-dom/client";
import PortfolioLayout from "./Pages/PortfolioLayout";
import HomePage from "./Pages/Home";
import "./styles/main.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Projects from "./Pages/Projects";
import { PixiLanding } from "./Pages/PixijsPages/PixiLanding";
import { NetworkConfig, buildConfig } from "./DataCenter";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <NetworkConfig.Provider value={buildConfig}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<PortfolioLayout content={<HomePage />} />}
          />
          <Route path="/pixilanding" element={<PixiLanding />} />
          {/* <Route
            path="/resume"
            element={<PortfolioLayout content={<Resume />} />}
          ></Route> */}
          <Route
            path="/projects"
            element={<PortfolioLayout content={<Projects />} />}
          />
        </Routes>
      </BrowserRouter>
    </NetworkConfig.Provider>
  </React.StrictMode>
);
