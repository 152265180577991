export const Footer = () => {
  return (
    <section className="footer">
      <footer>
        <i className="fa fa-copyright"/>
        {new Date().getFullYear()} Copy Right: jinming.ca
      </footer>
    </section>
  );
};
