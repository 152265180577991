import { Fragment } from "react";
import { Card } from "react-bootstrap";
export interface CardStyleConfig {
  borderColorStart: string;
  borderColorEnd: string;
}
export interface Props {
  time: string;
  imageUrl: string;
  title?: string;
  company?: string;
  position: string;
  description: string[];
  styles: CardStyleConfig;
}

const WorkProject = (props: Props) => {
  const data = props;
  let cardStyle: React.CSSProperties = {
    position: "absolute",
    top: "-4px",
    right: "-4px",
    bottom: "-4px",
    left: "-4px",
    background: `linear-gradient(to bottom right, ${data.styles.borderColorStart}, 50%, ${data.styles.borderColorEnd} 100%)`,
    content: "",
    zIndex: "-1",
    borderRadius: "15px",
  };

  const card = (
    <Fragment>
      <div className="workproject-card">
        <Card className="workproject-card-topimage">
          <Card.Img variant="top" src={data.imageUrl} />
          {data.title != null && data.company != null ? (
            <Card.ImgOverlay>
              <Card.Title className="workproject-card-title">
                {data.title}
              </Card.Title>
              <Card.Title className="workproject-card-title">
                {data.company}
              </Card.Title>
            </Card.ImgOverlay>
          ) : null}
        </Card>
        <div className="workproject-card-body">
          <div>
            <div>{data.position}</div>
            <div>{data.time}</div>
            <ul>
              {data.description.map((e, i) => (
                <li key={`workproject-description-${i}`}>{e}</li>
              ))}
            </ul>
          </div>
        </div>
        <div style={cardStyle} />
      </div>
    </Fragment>
  );
  return <div className="keyProject">{card}</div>;
};
export default WorkProject;
